import { IDefaultOffer } from 'lib/defaultOffers'
import { ActionsHeader } from '../../components/ActionsHeader'
import { OfferItem } from './OfferItem'
import {
  handleDefaultOfferClick,
  offerStatus,
  toggleDefaultOffer
} from 'store/callsession/callsessionSlice'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'store/ConfigureStore'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { Box } from '@mui/system'

interface OffersTableProps {
  /* eslint-disable-next-line no-unused-vars */
  onCheckOffer: (currentOffer: IDefaultOffer) => void
}

export const OffersTable: React.FC<OffersTableProps> = ({
  onCheckOffer
}: OffersTableProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('offers')

  const offers = useAppSelector(
    (state) => state.agentCallSession.callsession_default_offers
  )
  if (!offers?.length) return <></>

  const canDisplayOffers = offers.find((offer) => !!offer.isDisplayed)
  if (!canDisplayOffers) return <></>

  const getColumns = () => {
    return Object.values(offerStatus).map((offer) => t(offer.name))
  }

  const clickOffer = (offer: IDefaultOffer, accepted: boolean): void => {
    const currentOffer = {
      ...offer,
      accepted,
      rejected: !accepted,
      notOffered: false
    }
    onCheckOffer(currentOffer)
  }

  const handleAccept = (offer: IDefaultOffer) => {
    dispatch(handleDefaultOfferClick({ id: offer.id, btn: 'accepted' }))
    clickOffer(offer, true)
  }

  const handleReject = (offer: IDefaultOffer) => {
    dispatch(handleDefaultOfferClick({ id: offer.id, btn: 'rejected' }))
    clickOffer(offer, false)
  }

  const renderOfferItem = (offer: IDefaultOffer, key: number) => {
    return (
      <OfferItem
        key={key}
        id={offer.id}
        idx={key}
        type='defaultOffer'
        disabled={false}
        title={offer.name}
        script={offer.script}
        accepted={offer.accepted}
        rejected={offer.rejected}
        notOffered={offer.notOffered}
        expanded={offer.expanded}
        onAcceptClick={() => handleAccept(offer)}
        onRejectClick={() => handleReject(offer)}
        onNotOfferedClick={() =>
          dispatch(handleDefaultOfferClick({ id: offer.id, btn: 'notOffered' }))
        }
        onToggle={() => dispatch(toggleDefaultOffer(offer.id))}
      />
    )
  }

  return (
    <Box>
      <Box sx={{ textTransform: 'capitalize' }}>
        <ActionsHeader
          name='Offer name' // TO BE UPDATED
          columns={getColumns()}
        />
        {offers?.map((offer, idx) => {
          if (offer.isDisplayed) return renderOfferItem(offer, idx)
        })}
      </Box>
    </Box>
  )
}
