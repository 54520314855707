import React, { useEffect, useState } from 'react'
import {
  DataGridPro,
  type GridColumns,
  GridSortDirection
} from '@mui/x-data-grid-pro'
import { RecentCustomerInteractionColumns } from './RecentCustomerInteractionColumns'
import {
  type RootState,
  useAppDispatch,
  useAppSelector
} from '../../store/ConfigureStore'
import { fetchCallSessions } from '../../buddy-api/api/callsession/CallSessionAPI'
import { ICallSession } from '../../buddy-api'
import { useTranslation } from 'react-i18next'

const RecentCustomerInteraction = (): React.ReactElement => {
  const { t } = useTranslation('recentCustomerInteractionColumns')
  const callsessionDispatch = useAppDispatch()

  const { callSessions, user, count } = useAppSelector((state: RootState) => ({
    callSessions: state.callSessionsAPI.callSessions,
    user: state.authAPI.user,
    count: state.callSessionsAPI.count
  }))

  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)

  useEffect(() => {
    const limit = pageSize
    const offset = page * pageSize
    callsessionDispatch(fetchCallSessions({ user_id: user.id, limit, offset }))
  }, [callsessionDispatch, page, pageSize])

  const recentCustomerInteractionsColumns: GridColumns<ICallSession> =
    React.useMemo(() => RecentCustomerInteractionColumns(t), [t])

  const initialState = {
    sorting: {
      sortModel: [{ field: 'start_at', sort: 'desc' as GridSortDirection }]
    }
  }

  return (
    <DataGridPro
      disableSelectionOnClick
      rows={callSessions}
      columns={recentCustomerInteractionsColumns}
      headerHeight={40}
      rowHeight={52}
      getRowId={(row) => row.id ?? 0}
      pageSize={pageSize}
      rowCount={count}
      rowsPerPageOptions={[10, 25, 50]}
      pagination
      paginationMode='server'
      onPageChange={(newPage) => setPage(newPage)}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      initialState={initialState}
    />
  )
}

export default RecentCustomerInteraction
