import React, { useEffect, useState } from 'react'
import {
  useAppDispatch,
  useAppSelector
} from '../../../../../../store/ConfigureStore'
import {
  updatePlan,
  updateDataUsage,
  updateDevice,
  updateLines
} from 'store/callsession/callsessionSlice'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  List,
  ListItem,
  ListItemText
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Required } from 'components/input/Required'

interface IDiscoveryOption {
  name: string
  backendName?: string
  label: string
  options: {
    value: string
    mappedValue?: string // property to temp store the value that will be persisted (only used in "plan")
    label: string
  }[]
}

interface IDiscoveryOptions {
  [key: string]: IDiscoveryOption
}

export const discoveryOptionsMap: IDiscoveryOptions = {
  plan: {
    name: 'plan',
    label: 'Plan',
    options: [
      { value: 'bronze', label: 'Bronze Unlimited ($35)' },
      { value: 'silver', mappedValue: 'sgp', label: 'Silver Unlimited ($45)' },
      { value: 'gold', mappedValue: 'sgp', label: 'Gold Unlimited ($55)' },
      {
        value: 'platinum',
        mappedValue: 'sgp',
        label: 'Platinum Unlimited ($65)'
      },
      { value: 'other', mappedValue: 'other', label: 'Other (Legacy)' }
    ]
  },
  device: {
    name: 'device',
    label: 'Device',
    options: [
      { value: '4g', label: '4G' },
      { value: '5g', label: '5G' }
    ]
  },
  dataUsage: {
    name: 'dataUsage',
    backendName: 'data_usage',
    label: 'Data usage',
    options: [
      { value: 'less_10gb', label: '< 10gb/mo' },
      { value: 'greater_10gb', label: '> 10gb/mo' }
    ]
  },
  lines: {
    name: 'lines',
    backendName: 'lines',
    label: 'Lines',
    options: [
      { value: '1', label: '1' },
      { value: 'multiple', label: 'Multiple' }
    ]
  }
}

export const DevicePlanDataDiscovery: React.FC = () => {
  const dispatch = useAppDispatch()
  const rootCause = useAppSelector(
    (state) => state.agentCallSession.call_session_root_cause
  )
  const { t } = useTranslation('devicePlanDataDiscovery')

  if (!rootCause) return <></>

  const [selectedPlan, setSelectedPlan] = useState<string | null>(null)

  const selectedValue = {
    plan: useAppSelector((state) => state.agentCallSession.plan),
    device: useAppSelector((state) => state.agentCallSession.device),
    dataUsage: useAppSelector((state) => state.agentCallSession.data_usage),
    lines: useAppSelector((state) => state.agentCallSession.lines)
  }

  const handleDiscoveryChange = (event: any, type: string) => {
    if (!event || !type) return

    const value = event.target.value
    const option = discoveryOptionsMap[type].options.find(
      (opt) => opt.value === value
    )

    const discoveryValue = option?.mappedValue || value
    if (type === discoveryOptionsMap.plan.name) {
      dispatch(updatePlan(discoveryValue)) // Store the value to be persisted ('sgp')
      setSelectedPlan(value) // Store the selected value locally
    } else if (type === discoveryOptionsMap.device.name) {
      dispatch(updateDevice(discoveryValue))
    } else if (type === discoveryOptionsMap.dataUsage.name) {
      dispatch(updateDataUsage(discoveryValue))
    } else if (type === discoveryOptionsMap.lines.name) {
      dispatch(updateLines(discoveryValue))
    }
  }

  useEffect(() => {
    if (!selectedValue.plan) {
      setSelectedPlan(null)
    }
  }, [selectedValue.plan])

  const getPlanDisplayValue = () => {
    return selectedPlan ? selectedPlan : selectedValue.plan
  }

  const renderListItem = (primaryText: string) => (
    <ListItem sx={{ display: 'list-item', p: 0, pl: 1 }}>
      <ListItemText primary={primaryText} />
    </ListItem>
  )

  const DiscoveryRadioGroup = ({
    discovery,
    value
  }: {
    discovery: IDiscoveryOption
    value: string | null
  }) => {
    return (
      <FormControl sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            px: 1,
            borderTop: '1px solid #ccc'
          }}
        >
          <FormLabel
            key={value}
            id={t(`${value}-label`)}
            sx={{ maxWidth: '75px', minWidth: '75px', fontWeight: 600 }}
          >
            {t(discovery.name)}:
          </FormLabel>
          <RadioGroup
            key={`radio-group-${value}`}
            row
            aria-labelledby={`${value}-label`}
            name={discovery.backendName || discovery.name}
            value={value}
            onChange={(e) => handleDiscoveryChange(e, discovery.name)}
          >
            {discovery.options.map((option: any) => {
              return (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              )
            })}
          </RadioGroup>
        </Box>
      </FormControl>
    )
  }

  const optionsAreFilled =
    selectedValue.plan &&
    selectedValue.device &&
    selectedValue.dataUsage &&
    selectedValue.lines
  const borderColor = optionsAreFilled ? `#ccc` : `red`

  return (
    <Box
      key={t(`discoveryTitle.${rootCause.name}`)}
      sx={{
        border: `1px solid ${borderColor}`,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box sx={{ p: 1 }}>
        <Typography variant='h2'>
          {t('eligibilityCriteria.title')}
          <Required />
        </Typography>
      </Box>
      <Box sx={{ p: 1 }}>
        <Typography sx={{ fontWeight: 'bold' }}>
          {t('eligibilityCriteria.subTitle')}
        </Typography>
        <List sx={{ listStyleType: 'disc', pl: 2 }}>
          {renderListItem(t('eligibilityCriteria.questions.3'))}
          {renderListItem(t('eligibilityCriteria.questions.4'))}
          {renderListItem(t('eligibilityCriteria.questions.5'))}
          {renderListItem(t('eligibilityCriteria.questions.6'))}
        </List>
      </Box>

      {Object.values(discoveryOptionsMap).map(
        (discoveryOption: IDiscoveryOption, index: number) => {
          const persistedValue =
            selectedValue[discoveryOption.name as keyof typeof selectedValue]
          const discoveryValue =
            discoveryOption.name === 'plan'
              ? getPlanDisplayValue()
              : persistedValue

          return (
            <Box key={index}>
              <DiscoveryRadioGroup
                discovery={discoveryOption}
                value={discoveryValue}
                key={index}
              />
            </Box>
          )
        }
      )}
    </Box>
  )
}
