import { IDefaultOffer } from 'lib/defaultOffers'
import { useAppSelector } from 'store/ConfigureStore'
import { useIsInactiveCustomer } from 'utils/useIsDisconnectedCustomer'

const areOffersValid = (offers: IDefaultOffer[]): boolean => {
  if (!offers) return false

  const allRejected = offers.every((offer) => offer.rejected)
  const oneAccepted = offers.some((offer) => offer.accepted)

  return oneAccepted || allRejected
}
export const useCanOpenActions = () => {
  if (useIsInactiveCustomer()) return true

  const defaultOffers = useAppSelector(
    (state) => state.agentCallSession.callsession_default_offers
  )
  return areOffersValid(defaultOffers)
}
