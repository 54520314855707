import React from 'react'
import { BaseItem } from '../../components/BaseItem'
import { Box } from '@mui/system'
import { ScriptBlock } from '../../components/ScriptBlock'
import { OfferItemControls } from './OfferItemControls'
import { OfferAmount } from './OfferAmount'
import { OfferValues } from 'lib/calcDiscount'

interface IOfferItem {
  id: number
  offerNo?: number
  idx: number
  disabled: boolean
  title: string
  script: string
  accepted: boolean
  rejected: boolean
  notOffered: boolean
  expanded?: boolean
  type: 'offer' | 'optOffer' | 'defaultOffer'
  discountSummary?: OfferValues
  onNotOfferedClick: (id: number, notOffered: boolean) => void // eslint-disable-line no-unused-vars
  onAcceptClick: () => void
  onRejectClick: () => void
  onToggle: () => void
}

const getControls = (props: IOfferItem) => {
  return <OfferItemControls {...props} />
}

export const OfferItem = (props: IOfferItem) => {
  const offerTypes = ['offer', 'optOffer', 'defaultOffer']

  const expanded = props.expanded ?? false
  const displayTitle =
    offerTypes.includes(props.type) ||
    (props.type === 'offer' && !props.disabled) ||
    props.accepted ||
    props.rejected ||
    props.notOffered

  const onToggle = () => {
    if (props.disabled) return
    props.onToggle()
  }

  return (
    <Box>
      <BaseItem
        {...props}
        controls={getControls(props)}
        disabled={props.disabled || !props.script}
        expanded={!props.disabled && expanded}
        onToggle={onToggle}
        title={displayTitle ? props.title : `Offer ${props.offerNo}`}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '20px',
            marginTop: '10px'
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <ScriptBlock script={props.script} />
          </Box>
          {props.discountSummary && (
            <Box>
              <OfferAmount summary={props.discountSummary} />
            </Box>
          )}
        </Box>
      </BaseItem>
    </Box>
  )
}
