import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box } from '@mui/material'

import { useAppSelector } from 'store/ConfigureStore'
import { setDefaultOffers } from 'store/callsession/callsessionSlice'
import {
  buildCallsessionDefaultOffer,
  getOrderOffersByDisplay,
  IDefaultOffer
} from 'lib/defaultOffers'
import {
  completeSection,
  CallFlowSection,
  openSection,
  incompleteSection,
  closeSection
} from 'store/callflow/collapsableSections'
import { OffersTable } from './OffersTable'
import { InstructionsRow } from './InstructionsRow'

export interface Offer {
  offer_ladder_id: number
  offer_product_type: string
  priority: number
  name: string
  script: string
  is_active: boolean
}

export const OffersSection = () => {
  const dispatch = useDispatch()

  const { allOffers, defaultOffers } = useAppSelector((state) => ({
    allOffers: state.offersAPI.offers,
    defaultOffers: state.agentCallSession.callsession_default_offers
  }))

  // Format offers and sort device and discount ones
  const isDeviceFirst = [3, 4].includes(allOffers[0]?.offer_ladder_id)
  useEffect(() => {
    let offers = allOffers.map((offer) => buildCallsessionDefaultOffer(offer))
    offers = getOrderOffersByDisplay(offers, isDeviceFirst)
    dispatch(setDefaultOffers(offers))
  }, [allOffers])

  const areOffersValid = (offers: IDefaultOffer[]): boolean => {
    const allRejected = offers.every((offer) => offer.rejected)
    const oneAccepted = offers.some((offer) => offer.accepted)

    return oneAccepted || allRejected
  }
  const areOffersComplete = (offers: IDefaultOffer[]): boolean => {
    if (!areOffersValid(offers)) {
      dispatch(incompleteSection(CallFlowSection.OFFERS))
      dispatch(closeSection(CallFlowSection.ACTIONS))
      dispatch(closeSection(CallFlowSection.SESSION_OUTCOME))
      return false

      // If it's valid, go to next section
    } else {
      dispatch(completeSection(CallFlowSection.OFFERS))
      dispatch(openSection(CallFlowSection.ACTIONS))
      return true
    }
  }

  const onOfferChange = (currentOffer: IDefaultOffer) => {
    const currentOfferDisplayOrder = currentOffer.displayOrder
    if (currentOfferDisplayOrder === undefined) return

    const nextOfferIndex = currentOfferDisplayOrder + 1
    const updatedOffers = defaultOffers.map((offer) => {
      // If current offer, update its state
      if (offer.displayOrder === currentOffer.displayOrder) return currentOffer

      // If next offer, display it
      if (offer.displayOrder === nextOfferIndex) {
        return { ...offer, isDisplayed: true }
      }

      // Just return offer as it is
      return offer
    })

    if (areOffersComplete(updatedOffers)) return
    dispatch(setDefaultOffers(updatedOffers))
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <InstructionsRow />

      <OffersTable
        onCheckOffer={(currentOffer) => onOfferChange(currentOffer)}
      />
    </Box>
  )
}
