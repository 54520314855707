import { createSlice } from '@reduxjs/toolkit'
import { CallFlowSection, openSection } from '../collapsableSections'
import { AppDispatch, RootState } from '../../ConfigureStore'
import { isIntroductionSectionValid } from './introduction'
import { isContextSectionValid } from './contextSettings'
import { AgentCallSession } from '../../callsession/callsessionSlice'
import { isSaveSessionValid } from './saveSession'

// Array to maintain order
export const sectionOrder = [
  CallFlowSection.INTRODUCTION,
  CallFlowSection.CONTEXT_SETTING,
  CallFlowSection.OFFERS,
  CallFlowSection.ACTIONS,
  CallFlowSection.SESSION_OUTCOME,
  CallFlowSection.CLOSING_REMARKS,
  CallFlowSection.SAVE_SESSION_PANEL
]

function canAccessSection(
  targetSection: CallFlowSection,
  state: AgentCallSession
): boolean {
  const currentSection = getLastValidSection(state)
  const currentIndex = sectionOrder.indexOf(currentSection.callFlowSection)
  const targetIndex = sectionOrder.indexOf(targetSection)
  return targetIndex <= currentIndex
}

function getLastValidSection(state: AgentCallSession): {
  callFlowSection: CallFlowSection
  invalidFIeld?: string
} {
  // You would have validation checks here and return the last valid section
  // Example (simplified):
  if (state.no_account_items) {
    if (!isSaveSessionValid(state)) {
      return { callFlowSection: CallFlowSection.CLOSING_REMARKS }
    } else {
      return { callFlowSection: CallFlowSection.SAVE_SESSION_PANEL } // Assume all valid by default
    }
  }
  if (
    state.call_session_subroot_cause &&
    state.call_session_subroot_cause?.length < 1
  ) {
    if (!isContextSectionValid(state).isValid) {
      return {
        callFlowSection: CallFlowSection.CONTEXT_SETTING,
        invalidFIeld: isContextSectionValid(state).invalidField
      }
    } else if (!isSaveSessionValid(state)) {
      return { callFlowSection: CallFlowSection.CLOSING_REMARKS }
    }
  }
  if (!isIntroductionSectionValid(state)) {
    return { callFlowSection: CallFlowSection.INTRODUCTION }
  } else if (!isContextSectionValid(state).isValid) {
    return {
      callFlowSection: CallFlowSection.CONTEXT_SETTING,
      invalidFIeld: isContextSectionValid(state).invalidField
    }
  } else if (!state.outcome) {
    return { callFlowSection: CallFlowSection.SESSION_OUTCOME }
  } else if (!isSaveSessionValid(state)) {
    return { callFlowSection: CallFlowSection.CLOSING_REMARKS }
  }
  // Continue for other sections
  return { callFlowSection: CallFlowSection.SAVE_SESSION_PANEL } // Assume all valid by default
}

export interface CallFlowValidationState {
  errorSnackbarOpen: boolean
  snackBarMessage: string
}

const initialCallFlowValidationState: CallFlowValidationState = {
  errorSnackbarOpen: false,
  snackBarMessage: ''
}

export const callFlowValidationSlice = createSlice({
  name: 'callFlowValidation',
  initialState: initialCallFlowValidationState,
  reducers: {
    openErrorSnackBar: (state: CallFlowValidationState) => {
      state.errorSnackbarOpen = true
    },
    closeErrorSnackBar: (state: CallFlowValidationState) => {
      state.errorSnackbarOpen = false
    },
    updateSnackBarMessage: (
      state: CallFlowValidationState,
      action: { payload: string }
    ) => {
      state.snackBarMessage = action.payload
    }
  }
})

export const openSectionIfValidated =
  (section: CallFlowSection) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const currentState = getState().agentCallSession

    // Pass only the necessary part of the state to getLastValidSection
    if (!canAccessSection(section, currentState)) {
      const lastValidSection = getLastValidSection(currentState) // Adjust according to your state structure
      if (
        lastValidSection.callFlowSection === CallFlowSection.CLOSING_REMARKS
      ) {
        // CallFlow is valid, but session outcome panel not
        dispatch(
          updateSnackBarMessage(
            `Please complete the ${CallFlowSection.SAVE_SESSION_PANEL.toLowerCase().replace('_', ' ')} section first`
          )
        )
        dispatch(openErrorSnackBar())
      } else {
        const incompleteFiled =
          lastValidSection.invalidFIeld ||
          lastValidSection.callFlowSection.toLowerCase().replace('_', ' ')
        dispatch(
          updateSnackBarMessage(
            `Please complete the ${incompleteFiled} section first`
          )
        )
        dispatch(openErrorSnackBar())
        dispatch(openSection(lastValidSection.callFlowSection))
      }
      return
    }
    dispatch(openSection(section))
  }

export const { openErrorSnackBar, closeErrorSnackBar, updateSnackBarMessage } =
  callFlowValidationSlice.actions
