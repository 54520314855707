import { Box, IconButton, SelectChangeEvent, Tooltip } from '@mui/material'
import { InputLabel } from 'components/input/InputLabel'
import { Dropdown } from 'components/input/Dropdown'
import { Required } from 'components/input/Required'
import React, { ReactNode } from 'react'
import { Column, Row } from 'ui'
import { useAppDispatch, useAppSelector } from '../../../store/ConfigureStore'
import { getBattleCard } from '../../../buddy-api/api/BattleCardAPI'
import { Stack } from '@mui/system'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { IBattleCardCompetitor } from '../../../buddy-api/types/BattleCardCompetitor'
import { updateBattleCardDropdownSelectedCompetitor } from '../../../store/battlecard/battlecardSlice'
import { useTranslation } from 'react-i18next'

interface BattleCardInsightsProps {
  data: { [key: string]: string | string[] }
}

const InputRow = ({
  label,
  inputElem,
  required,
  inputWidth
}: {
  label: string
  inputElem: ReactNode
  required?: boolean
  inputWidth?: string
}) => {
  return (
    <Stack direction='row' alignItems='center'>
      <Box sx={{ whiteSpace: 'nowrap' }}>
        <InputLabel>{label}</InputLabel>
        {required && <Required />}
      </Box>
      <Box sx={{ ml: '9px', width: inputWidth ?? '100%' }}>{inputElem}</Box>
    </Stack>
  )
}

const BattleCardInsightsList: React.FC<BattleCardInsightsProps> = ({
  data
}) => {
  const { t } = useTranslation('battleCardKeys')
  return (
    <ul
      style={{
        padding: '0px',
        fontSize: '13px',
        color: '#3A404D'
      }}
    >
      {Object.entries(data).map(([key, value]) =>
        value !== '' ? (
          <li key={key}>
            <strong>{t(key)}</strong>:{' '}
            {Array.isArray(value) && value.length > 1
              ? value.map((item) => (
                  <React.Fragment key={item}>
                    <br />- {item}
                  </React.Fragment>
                ))
              : value}
          </li>
        ) : null
      )}
    </ul>
  )
}

export const BattleCardInsights: React.FC = () => {
  const { t } = useTranslation('battleCardInsights')

  const dispatch = useAppDispatch()

  const {
    battlecardDropdownSlice: { selectedCompetitor },
    battlecardCompetitorAPI: { battle_card_competitors },
    battleCardAPI: { battleCard },
    agentCallSession: { selectedLanguage }
  } = useAppSelector((state) => state)

  const handleCompetitorSelection = (event: SelectChangeEvent) => {
    const selectedCompetitor = battle_card_competitors.find(
      (competitor: IBattleCardCompetitor) =>
        competitor.id === (event.target.value as unknown as number)
    )

    if (selectedCompetitor) {
      dispatch(updateBattleCardDropdownSelectedCompetitor(selectedCompetitor))
      dispatch(
        getBattleCard({
          competitor_id: selectedCompetitor.id,
          state_code: 'all'
        })
      )
    }
  }

  React.useEffect(() => {
    if (selectedLanguage) {
      if (selectedCompetitor) {
        dispatch(
          getBattleCard({
            competitor_id: selectedCompetitor.id,
            state_code: 'all'
          })
        )
      }
    }
  }, [selectedLanguage])

  const convertCompetitors = () => {
    return battle_card_competitors.map((competitor: IBattleCardCompetitor) => ({
      id: competitor.id,
      name: competitor.name,
      label: competitor.name
    }))
  }

  return (
    <Row
      sx={{
        alignItems: 'left',
        mt: 1.5,
        mb: 1.5,
        mx: 1.5,
        px: 0.5,
        py: 1.5,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '3px'
      }}
    >
      <Row
        sx={{
          mt: 1.5,
          mb: 1.5,
          mx: 1.5,
          px: 2.5,
          py: 1.5
        }}
      >
        <Column flex={1}>
          <InputRow
            label={t('competitorLabel')}
            required={false}
            inputElem={
              <Box display='flex'>
                <Dropdown
                  id='battle-card-competitor-dropdown'
                  value={selectedCompetitor?.id.toString()}
                  items={convertCompetitors()}
                  onChange={handleCompetitorSelection}
                  dividerIndex={battle_card_competitors.length - 2}
                />
                <IconButton
                  size='small'
                  sx={{ cursor: 'pointer', pl: 1, mr: -3 }}
                  disabled={!battleCard?.battle_card_competitor?.url}
                  href={battleCard?.battle_card_competitor?.url ?? ''}
                  target='_blank'
                >
                  <Tooltip title={t('goToCompetitorWebpage')} placement='top'>
                    <OpenInNewIcon
                      sx={{ fontSize: '20px' }}
                      color={
                        !battleCard?.battle_card_competitor?.url
                          ? 'disabled'
                          : 'primary'
                      }
                    />
                  </Tooltip>
                </IconButton>
              </Box>
            }
          />
        </Column>
      </Row>
      <Row>
        <Box
          sx={{
            color: '#3A404D',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '21px',
            pb: '12px',
            mt: 1,
            paddingLeft: '1rem',
            paddingRight: '1rem',
            wordWrap: 'break-word'
          }}
        >
          <ul
            style={{
              paddingLeft: '2rem',
              fontSize: '13px',
              color: '#3A404D'
            }}
          >
            <BattleCardInsightsList data={battleCard?.insights ?? {}} />
          </ul>
        </Box>
      </Row>
    </Row>
  )
}
