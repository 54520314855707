import React, { useEffect } from 'react'
import { Column, Row } from '../../ui'
import SaveIcon from '@mui/icons-material/Save'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  openErrorSnackBar,
  openSectionIfValidated,
  updateSnackBarMessage
} from '../../store/callflow/validation/validation'
import { CallFlowSection } from '../../store/callflow/collapsableSections'
import { useAppDispatch, useAppSelector } from '../../store/ConfigureStore'
import { isSaveSessionValid } from '../../store/callflow/validation/saveSession'
import {
  ICallSessionActionSave,
  ICallSessionCreate,
  IOfferSave
} from '../../buddy-api'
import { saveCallSession } from '../../buddy-api/api/callsession/CallSessionAPI'
import { isIntroductionSectionValid } from '../../store/callflow/validation/introduction'
import { isContextSectionValid } from '../../store/callflow/validation/contextSettings'
import { userLandingPage } from '../../routes/routes'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { IDropdownItem } from 'components/input/Dropdown'
import { YesNoOptions } from 'utils/enums'
import { IDefaultOffer } from 'lib/defaultOffers'

export const SaveSession: React.FC = () => {
  const { t } = useTranslation('saveSessionPanel')

  const dispatch = useAppDispatch()

  const { callsession_state, saveLoading, saveError } = useAppSelector(
    (state) => ({
      callsession_state: state.agentCallSession,
      saveLoading: state.callSessionsAPI.saveLoading,
      saveError: state.callSessionsAPI.saveError
    })
  )

  const navigate = useNavigate()
  const [allowNavigate, setAllowNavigate] = React.useState(false)

  const yesNoToBool = (value: IDropdownItem | null): boolean | null => {
    if (value === null) {
      return null
    }
    return value.name === YesNoOptions.yes
  }

  useEffect(() => {
    if (saveError === null && !saveLoading && allowNavigate) {
      setAllowNavigate(false)
      navigate(userLandingPage() + '/?saved=true')
    }
    if (saveError !== null && !saveLoading && allowNavigate) {
      dispatch(updateSnackBarMessage(saveError))
      dispatch(openErrorSnackBar())
    }
  }, [saveLoading])

  const formatOffers = (): IOfferSave[] => {
    const offers = callsession_state.callsession_default_offers
    return offers.map((offer: IDefaultOffer, idx) => {
      return {
        id: offer.id,
        order: idx + 1,
        title: offer.name,
        script: offer.script,
        offer_amount: 0, // to be updated
        offered: !offer.notOffered,
        accepted: offer.accepted,
        rejected: offer.rejected
      }
    })
  }

  const formatActions = (): ICallSessionActionSave[] => {
    return callsession_state.callsession_actions.map((action) => {
      return {
        action_id: action.id,
        accepted: action.accepted,
        rejected: action.rejected,
        offered: action.offered
      }
    })
  }

  const getSaveSession = (userId: number): ICallSessionCreate => {
    const getDefaultNumber = (value: number | undefined | null) => {
      return value ?? 0
    }
    const getDefaultString = (value: string | undefined | null) => {
      return value ?? ''
    }

    return {
      account_number: '',
      account_id: callsession_state.account_id ?? '',
      user_id: userId,

      intent_id: getDefaultNumber(callsession_state.call_session_intent?.id),
      root_cause_id: getDefaultNumber(
        callsession_state.call_session_root_cause?.id
      ),
      is_reroute: yesNoToBool(callsession_state.reroute),
      dropped_product_id: getDefaultNumber(
        callsession_state.dropped_product?.id
      ),

      selected_competitor: getDefaultString(
        callsession_state.selected_callsession_competitor?.name
      ),
      other_competitor_input: callsession_state.other_competitor_input,
      already_switched: yesNoToBool(
        callsession_state.already_switched_to_competitor
      ),

      comments: getDefaultString(callsession_state.comments),

      actions: formatActions(),
      action_comments: getDefaultString(callsession_state.action_comments),

      offers: formatOffers(),
      outcome_id: getDefaultNumber(callsession_state.outcome?.id),

      device: callsession_state.device,
      data_usage: callsession_state.data_usage,
      plan: callsession_state.plan,
      lines: callsession_state.lines,

      selected_language: callsession_state.selectedLanguage,

      pin_requested: callsession_state.pinRequested,

      start_at: callsession_state.start_at,
      end_at: new Date().toISOString()
    }
  }

  const onSave = () => {
    const canSave =
      isIntroductionSectionValid(callsession_state) &&
      isContextSectionValid(callsession_state) &&
      isSaveSessionValid(callsession_state)

    if (!canSave || !callsession_state.user) return

    dispatch(openSectionIfValidated(CallFlowSection.SAVE_SESSION_PANEL))

    // Save the session - persist data
    const saveSession = getSaveSession(callsession_state.user.id)
    setAllowNavigate(true)
    dispatch(saveCallSession(saveSession))
  }

  return (
    <Row
      sx={{
        padding: '16px',
        margin: '10px'
      }}
    >
      <Column sx={{ flex: 4 }}></Column>
      <Column sx={{ flex: 10 }}>
        <Row>
          <LoadingButton
            variant='contained'
            id='call-session-button-save-session'
            data-cy={'call-session-button-save-session'}
            onClick={() => onSave()}
            disabled={false}
            color='primary'
            sx={{
              height: 'fit-content',
              width: 'fit-content',
              marginLeft: 'auto',
              mb: 1
            }}
            startIcon={<SaveIcon />}
            loading={saveLoading}
          >
            {t('save')}
          </LoadingButton>
        </Row>
      </Column>
    </Row>
  )
}