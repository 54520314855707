import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IUser } from 'buddy-api/types'
import { ICallSessionOutcome } from 'buddy-api/types/callsession/CallSessionOutcome'
import { ICustomer } from '../../buddy-api/types/customer/Customer'

import { ICallSessionOffer } from '../../buddy-api/types/callsession/CallSessionOffer'
import { ICallSessionAction } from '../../buddy-api/types/callsession/CallSessionAction'
import { ICallSessionRootCause } from '../../buddy-api/types/callsession/CallSessionRootCause'
import { ICallSessionIntent } from '../../buddy-api/types/callsession/CallSessionIntent'
import { IProduct } from '../../buddy-api/types/Product'
import { ICustomerMoveTypeOption } from '../../buddy-api/types/callsession/CustomerMoveTypeOptions'
import { ICustomerMoveNewTechOption } from '../../buddy-api/types/callsession/CustomerMoveNewTechOptions'
import { IBattleCardCompetitor } from '../../buddy-api/types/BattleCardCompetitor'
import { ICompetitor } from '../../buddy-api/types/customer/CustomerCompetitor'
import { IAction } from '../../buddy-api/types/Action'
import {
  OfferedOptions,
  ServiceCallAbout,
  YesNoOptions
} from '../../utils/enums'
import { IDropdownItem } from '../../components/input/Dropdown'
import { ICallsessionOfferedOption } from '../../buddy-api/types/callsession/OfferedOptions'
import { buildCallSessionOffers } from 'lib/offers'
import { buildCallSessionOptOffers } from 'lib/optOffers'
import { IOffer } from 'buddy-api/types/Offer'
import { IWinbackSpeed } from 'buddy-api/types/callsession/WinbackSpeed'
import { buildWinbackOffers } from 'lib/winBackOffers'
import { IDefaultOffer } from 'lib/defaultOffers'
import { discoveryOptionsMap } from 'pages/callsession/CallFlow/sections/ContextSettings/intentFlows/DevicePlanDataDiscovery'
import { Languages } from 'store/languages'

export interface IOptOffer {
  type: 'VAS' | 'Video' | 'BB'
  name: string
  script: string
  amount: number
}

export interface ICallSessionOptOffer extends IOptOffer {
  id: number
  accepted: boolean
  rejected: boolean
  notOffered: boolean
  expanded: boolean
}

export interface IOfferBtnClick {
  id: number
  btn: 'accepted' | 'rejected' | 'notOffered'
}

export interface IWinbackOffer {
  id: number
  product: 'BB' | 'Voice' | 'Video'
  title: string
  script?: string
  offerAmount: number
  notOffered: boolean
  accepted: boolean
  rejected: boolean
  expanded: boolean
}

export const YesNoDropdownItems: IDropdownItem[] = [
  {
    id: 1,
    name: YesNoOptions.yes,
    label: YesNoOptions.yes
  },
  {
    id: 2,
    name: YesNoOptions.no,
    label: YesNoOptions.no
  }
]

interface IDropButtonClick {
  offerId: number
  button: 'yes' | 'no'
}

const offeredOptionsDefault: ICallsessionOfferedOption = {
  id: 3,
  name: OfferedOptions.notOffered,
  label: OfferedOptions.notOffered
}

export interface IRootCause {
  [key: string]: {
    name: string
    discoveryTitle: string
    discoveryOptions: string[]
  }
}
export const rootCauseMap: IRootCause = {
  billingError: {
    name: 'billingError',
    discoveryTitle: 'Billing Error - Offers',
    discoveryOptions: [
      discoveryOptionsMap.plan.name,
      discoveryOptionsMap.device.name
    ]
  },
  customerService: {
    name: 'customerService',
    discoveryTitle: 'Customer Service - Offers',
    discoveryOptions: [
      discoveryOptionsMap.plan.name,
      discoveryOptionsMap.device.name
    ]
  },
  device: {
    name: 'device',
    discoveryTitle: 'Device - Upgrade',
    discoveryOptions: [
      discoveryOptionsMap.plan.name,
      discoveryOptionsMap.device.name
    ]
  },
  financialHardship: {
    name: 'financialHardship',
    discoveryTitle: 'Financial Hardship - Stepdown',
    discoveryOptions: [
      discoveryOptionsMap.plan.name,
      discoveryOptionsMap.dataUsage.name
    ]
  },
  network: {
    name: 'network',
    discoveryTitle: 'Network - Troubleshoot',
    discoveryOptions: [
      discoveryOptionsMap.plan.name,
      discoveryOptionsMap.device.name
    ]
  },
  pricing: {
    name: 'pricing',
    discoveryTitle: 'Pricing - Stepdown',
    discoveryOptions: [
      discoveryOptionsMap.plan.name,
      discoveryOptionsMap.dataUsage.name
    ]
  },
  dataPrivacy: {
    name: 'dataPrivacy',
    discoveryTitle: 'Data privacy /security - Offers',
    discoveryOptions: [
      discoveryOptionsMap.plan.name,
      discoveryOptionsMap.device.name
    ]
  }
}

export const buildOfferString = (
  rootCauseName: string,
  plan: string | null,
  device: string | null,
  dataUsage: string | null,
  lines: string | null
) => {
  if (!rootCauseName) return ''

  const requiredFields = rootCauseMap[rootCauseName].discoveryOptions
  const discoveryStr = []

  if (plan && requiredFields.includes(discoveryOptionsMap.plan.name))
    discoveryStr.push(plan)
  if (device && requiredFields.includes(discoveryOptionsMap.device.name))
    discoveryStr.push(device)
  if (dataUsage && requiredFields.includes(discoveryOptionsMap.dataUsage.name))
    discoveryStr.push(dataUsage)
  if (lines && requiredFields.includes(discoveryOptionsMap.lines.name))
    discoveryStr.push(lines)

  return discoveryStr.join('_')
}

export const offerTypes = {
  device: { name: 'devices', label: 'Device', plural: 'Devices' },
  discount: { name: 'discounts', label: 'Discount', plural: 'Discounts' }
}

export const offerStatus = {
  accepted: { name: 'accepted', verboseName: 'Accepted', value: false },
  rejected: { name: 'rejected', verboseName: 'Rejected', value: false },
  notOffered: { name: 'notOffered', verboseName: 'Not offered', value: false }
}

const closeOffer = (offer: ICallSessionOffer) => {
  if (offer.accepted || offer.rejected || offer.notOffered) {
    offer.expanded = false
  }
}

const openNextOffer = (offers: ICallSessionOffer[], currentIdx: number) => {
  if (currentIdx + 1 >= offers.length) return
  const offer = offers[currentIdx + 1]
  if (!offer.accepted && !offer.rejected && !offer.notOffered)
    offer.expanded = true
}

const handleDropOffers = (offers: ICallSessionOffer[]) => {
  const dropOffers = offers.filter((offer) => offer.type === 'drop')
  for (const dropOffer of dropOffers) {
    const regularOffers = offers.filter(
      (offer) => offer.type !== 'drop' && offer.product === dropOffer.product
    )
    const nNotAccepted = regularOffers.filter(
      (offer) => offer.rejected || offer.notOffered
    ).length

    const shouldDisplay =
      dropOffer.hasNoOffers || regularOffers.length === nNotAccepted
    if (!dropOffer.display && shouldDisplay) {
      // Start display drop offer without anything selected
      dropOffer.accepted = false
      dropOffer.rejected = false
      dropOffer.selectedOfferId = undefined
    }

    dropOffer.display = shouldDisplay
  }
}

const handleAcceptedWinbackOffer = (
  acceptedOfferId: number,
  offers: IWinbackOffer[]
) => {
  for (const offer of offers) {
    if (offer.id !== acceptedOfferId && offer.accepted) {
      offer.accepted = false
      offer.rejected = true
    }
  }
}

const unClickOfferBtn = (
  offer: IWinbackOffer | ICallSessionOffer | IDefaultOffer,
  btn: string
): boolean => {
  if (offer.accepted && btn === offerStatus.accepted.name) {
    offer.accepted = false
    return false
  }
  if (offer.rejected && btn === offerStatus.rejected.name) {
    offer.rejected = false
    return false
  }
  if (offer.notOffered && btn === offerStatus.notOffered.name) {
    offer.notOffered = false
    return false
  }

  return true
}

const clickOfferBtn = (
  offer: IWinbackOffer | ICallSessionOffer | IDefaultOffer,
  btn: string
) => {
  offer.accepted = btn === offerStatus.accepted.name
  offer.rejected = btn === offerStatus.rejected.name
  offer.notOffered = btn === offerStatus.notOffered.name
}

export interface AgentCallSession {
  account_id: string | null
  call_session_intent: ICallSessionIntent | null
  call_session_root_cause: ICallSessionRootCause | null
  call_session_subroot_cause: string | null
  reroute: IDropdownItem | null

  selected_move_type: ICustomerMoveTypeOption | null
  selected_does_frontier_offer_service: IDropdownItem | null
  selected_customer_move_new_tech: ICustomerMoveNewTechOption | null
  selected_short_term_move_offer_accepted: ICallsessionOfferedOption | null
  selected_long_term_move_offer_accepted: ICallsessionOfferedOption | null
  new_customer_address_input: string | null

  already_switched_to_competitor: IDropdownItem | null
  offer_two_months_free: ICallsessionOfferedOption | null
  selected_callsession_competitor: ICompetitor | null
  callsession_battlecard_competitor: IBattleCardCompetitor | null
  other_competitor_input: string | null

  // Winback callflow
  selectedWinbackSpeed: IWinbackSpeed | null
  winbackOffers: IWinbackOffer[]

  customer: ICustomer | null
  dropped_product: IProduct | null
  user: IUser | null
  outcome: ICallSessionOutcome | null
  callsession_actions: ICallSessionAction[]
  callsession_offers: ICallSessionOffer[]
  callsession_opt_offers: ICallSessionOptOffer[]
  callsession_default_offers: IDefaultOffer[]

  starting_mrc: number
  ending_mrc: number

  start_at: string

  comments: string | null
  action_comments: string | null
  no_account_items: boolean

  dropOfferDialogOpen: boolean
  sessionCanBePerformed: boolean
  expandedActionIndex: number | null
  plan: string | null
  device: string | null
  data_usage: string | null
  lines: string | null
  transferPin?: string
  pinRequested: boolean

  /* Selected Language */
  selectedLanguage: Languages
}

const initialState: AgentCallSession = {
  account_id: null,

  call_session_intent: null,
  call_session_root_cause: null,
  call_session_subroot_cause: null,
  reroute: YesNoDropdownItems[1],

  already_switched_to_competitor: YesNoDropdownItems[1],
  other_competitor_input: null,
  offer_two_months_free: offeredOptionsDefault,
  selected_callsession_competitor: null,
  callsession_battlecard_competitor: null,

  selected_move_type: null,
  selected_does_frontier_offer_service: null,
  selected_customer_move_new_tech: null,
  selected_short_term_move_offer_accepted: offeredOptionsDefault,
  selected_long_term_move_offer_accepted: offeredOptionsDefault,
  new_customer_address_input: null,

  // Winback
  selectedWinbackSpeed: null,
  winbackOffers: [],

  customer: null,
  dropped_product: null,
  user: null,
  outcome: null,
  callsession_actions: [],
  callsession_offers: [],
  callsession_opt_offers: [],
  callsession_default_offers: [],

  starting_mrc: 0,
  ending_mrc: 0,

  start_at: '',

  comments: null,
  action_comments: null,
  no_account_items: false,

  dropOfferDialogOpen: false,
  sessionCanBePerformed: true,
  expandedActionIndex: null,
  plan: null,
  device: null,
  data_usage: null,
  lines: null,
  transferPin: '',
  selectedLanguage: Languages.ENGLISH,
  pinRequested: false
}

export const agentCallSessionSlice = createSlice({
  name: 'agentCallSession',
  initialState,
  reducers: {
    updateAgentCallSessionCustomer: (state, action) => {
      state.customer = action.payload
    },
    updateAgentCallSessionUser: (state, action) => {
      state.user = action.payload
    },
    updateAgentCallSessionOutcome: (state, action) => {
      state.outcome = action.payload
    },
    updateAgentSelectedCallSessionCompetitor: (state, action) => {
      state.selected_callsession_competitor = action.payload
    },
    updateAgentCallSessionActions: (state, action) => {
      state.callsession_actions = action.payload
    },
    updateAgentCallSessionOffers: (state, action: PayloadAction<IOffer[]>) => {
      if (!state.dropped_product) return
      state.callsession_offers = buildCallSessionOffers(
        action.payload,
        state.dropped_product
      )
    },
    updateAgentCallSessionStartAt: (state, action) => {
      state.start_at = action.payload
    },
    updateAgentCallSessionComments: (state, action) => {
      state.comments = action.payload
    },
    updateAgentCallSessionActionComments: (state, action) => {
      state.action_comments = action.payload
    },
    updateAgentCallSessionNoAccountItems: (state, action) => {
      state.no_account_items = action.payload
    },
    updateDroppedProductId: (state, action) => {
      state.dropped_product = action.payload
    },
    updateReroute: (state, action) => {
      state.reroute = action.payload
    },
    updateCallSessionRootCause: (state, action) => {
      state.call_session_root_cause = action.payload
    },
    updatePlan: (state, action) => {
      state.plan = action.payload
    },
    updateDevice: (state, action) => {
      state.device = action.payload
    },
    updateDataUsage: (state, action) => {
      state.data_usage = action.payload
    },
    updateLines: (state, action) => {
      state.lines = action.payload
    },
    updateCallSessionSubRootCause: (state, action) => {
      state.call_session_subroot_cause = action.payload
    },
    updateCallSessionIntent: (state, action) => {
      state.call_session_intent = action.payload
    },
    updateTransferPin: (state, action) => {
      state.transferPin = action.payload
    },
    updatePinRequested: (state, action) => {
      state.pinRequested = action.payload
    },
    updateWinbackOffers: (state, action: PayloadAction<IOffer[]>) => {
      state.winbackOffers = buildWinbackOffers(action.payload)
    },
    handleActionOffered: (state, action) => {
      const actionId: number = action.payload
      const customerAction = state.callsession_actions.find(
        (cAction: IAction) => cAction.id === actionId
      )
      if (!customerAction) return
      customerAction.offered = !customerAction.offered
    },
    updateCallsessionActionAccept: (state, action) => {
      const actionId = action.payload
      const customerAction = state.callsession_actions.find(
        (cAction) => cAction.id === actionId
      )
      if (!customerAction) return

      if (customerAction.accepted) {
        customerAction.accepted = false
      } else {
        customerAction.accepted = true
        customerAction.rejected = false
      }
    },
    updateCallsessionActionReject: (state, action) => {
      const actionId = action.payload
      const customerAction = state.callsession_actions.find(
        (cAction) => cAction.id === actionId
      )
      if (!customerAction) return

      if (customerAction.rejected) {
        customerAction.rejected = false
      } else {
        customerAction.rejected = true
        customerAction.accepted = false
      }
    },
    handleOfferNotOffered: (state, action) => {
      const offerId = action.payload
      const offerIdx = state.callsession_offers.findIndex(
        (offer) => offer.id === offerId
      )
      if (offerIdx < 0) return

      const offer = state.callsession_offers[offerIdx]

      // Update accepted / rejected / notOffered
      if (offer.notOffered) {
        offer.notOffered = false
      } else {
        offer.accepted = false
        offer.rejected = false
        offer.notOffered = true
      }

      // Close current offer
      closeOffer(offer)

      // Open next offer
      openNextOffer(state.callsession_offers, offerIdx)

      handleDropOffers(state.callsession_offers)
    },
    handleOfferAccepted: (state, action) => {
      const offerId = action.payload
      const offer = state.callsession_offers.find(
        (offer) => offer.id === offerId
      )
      if (!offer) return

      // Update accepted / rejected / notOffered
      if (offer.accepted) {
        offer.accepted = false
      } else {
        offer.accepted = true
        offer.rejected = false
        offer.notOffered = false
      }

      // Reset any offer below (if any)
      const offerIdx = state.callsession_offers.findIndex(
        (offer) => offer.id === offerId
      )
      if (offerIdx < 0) return
      for (let i = offerIdx + 1; i < state.callsession_offers.length; i++) {
        const offer = state.callsession_offers[i]
        if (offer.accepted || offer.rejected || offer.notOffered) {
          offer.accepted = false
          offer.rejected = false
          offer.notOffered = false
        }
      }

      // Check if a product was select in drop offer
      const dropOffer = state.callsession_offers.find(
        (item) => item.type === 'drop' && item.product === offer.product
      )
      if (dropOffer) {
        dropOffer.selectedOfferId = undefined
      }

      // Close current offer
      closeOffer(offer)
      handleDropOffers(state.callsession_offers)
    },
    handleOfferRejected: (state, action) => {
      const offerId = action.payload
      const offerIdx = state.callsession_offers.findIndex(
        (offer) => offer.id === offerId
      )
      if (offerIdx < 0) return

      const offer = state.callsession_offers[offerIdx]

      // Update
      if (offer.rejected) {
        offer.rejected = false
      } else {
        offer.accepted = false
        offer.rejected = true
        offer.notOffered = false
      }

      // Close current offer
      closeOffer(offer)

      // Open next offer
      openNextOffer(state.callsession_offers, offerIdx)

      handleDropOffers(state.callsession_offers)
    },
    handleDropProductClick: (
      state,
      action: PayloadAction<IDropButtonClick>
    ) => {
      const { offerId, button } = action.payload

      // If call session intent is "not to deactive", product cannot
      // be dropped, and DropOfferDialog should open
      if (
        button === 'yes' &&
        state.call_session_intent?.name === ServiceCallAbout.NotDeactivation
      ) {
        state.dropOfferDialogOpen = true
        return
      }

      const offerIdx = state.callsession_offers.findIndex(
        (offer) => offer.id === offerId
      )
      if (offerIdx < 0) return

      const offer = state.callsession_offers[offerIdx]

      // Update accepted and rejected
      if (button === 'yes') {
        offer.accepted = true
        offer.rejected = false
        offer.selectedOfferId = undefined
      } else {
        offer.rejected = true
        offer.accepted = false
      }

      // Open next offer
      openNextOffer(state.callsession_offers, offerIdx)
    },
    handleSelectedOfferFromDropProduct: (state, action) => {
      const { dropOfferId, selectedOfferId } = action.payload
      const dropOffer = state.callsession_offers.find(
        (offer) => offer.id === dropOfferId
      )
      if (!dropOffer) return

      dropOffer.selectedOfferId = selectedOfferId
      dropOffer.offer_amount =
        state.callsession_offers.find((offer) => offer.id === selectedOfferId)
          ?.offer_amount ?? 0
    },
    toggleOffer: (state, action: PayloadAction<number>) => {
      const offer = state.callsession_offers.find(
        (offer) => offer.id === action.payload
      )
      if (!offer) return
      offer.expanded = !offer.expanded
    },
    setOptOffers: (state, action: PayloadAction<IOptOffer[]>) => {
      state.callsession_opt_offers = buildCallSessionOptOffers(action.payload)
    },
    setDefaultOffers: (state, action: PayloadAction<IDefaultOffer[]>) => {
      state.callsession_default_offers = action.payload
    },
    handleOptOfferClicked: (state, action: PayloadAction<IOfferBtnClick>) => {
      const { id, btn } = action.payload
      const offer = state.callsession_offers.find((offer) => offer.id === id)
      if (!offer) return

      const isUnclick = unClickOfferBtn(offer, btn)
      if (!isUnclick) return

      clickOfferBtn(offer, btn)
    },
    handleDefaultOfferClick: (state, action: PayloadAction<IOfferBtnClick>) => {
      const { id, btn } = action.payload

      const offer = state.callsession_default_offers.find(
        (offer) => offer.id === id
      )
      if (!offer) return

      const isUnclick = unClickOfferBtn(offer, btn)
      if (!isUnclick) return

      clickOfferBtn(offer, btn)
    },
    toggleOptOffer: (state, action: PayloadAction<number>) => {
      const offer = state.callsession_opt_offers.find(
        (offer) => offer.id === action.payload
      )
      if (!offer) return

      offer.expanded = !offer.expanded
    },
    toggleDefaultOffer: (state, action: PayloadAction<number>) => {
      const offer = state.callsession_default_offers.find(
        (offer) => offer.id === action.payload
      )
      if (!offer) return
      offer.expanded = !offer.expanded
    },
    updateAlreadySwitchedToCompetitor: (state, action) => {
      state.already_switched_to_competitor = action.payload
    },
    updateSelectedMoveType: (state, action) => {
      state.selected_move_type = action.payload
    },
    updateSelectedDoesFrontierOfferService: (state, action) => {
      state.selected_does_frontier_offer_service = action.payload
    },
    updateNewCustomerAddress: (state, action) => {
      state.new_customer_address_input = action.payload
    },
    updateCustomerMoveNewTech: (state, action) => {
      state.selected_customer_move_new_tech = action.payload
    },
    updateShortTermMoveOfferAccepted: (state, action) => {
      state.selected_short_term_move_offer_accepted = action.payload
    },
    updateOtherCompetitorInput: (state, action) => {
      state.other_competitor_input = action.payload
    },
    updateCallsessionStartingMRC: (state, action) => {
      state.starting_mrc = action.payload
    },
    closeDropOfferDialog: (state) => {
      state.dropOfferDialogOpen = false
    },
    updateSessionCanBePerformed: (state, action) => {
      state.sessionCanBePerformed = action.payload
    },
    updateWinbackSpeed: (
      state,
      action: PayloadAction<IWinbackSpeed | null>
    ) => {
      state.selectedWinbackSpeed = action.payload
    },
    toggleWinbackOffer: (state, action: PayloadAction<number>) => {
      const offer = state.winbackOffers.find(
        (offer) => offer.id === action.payload
      )
      if (!offer) return
      offer.expanded = !offer.expanded
    },

    handleWinbackOfferClicked: (
      state,
      action: PayloadAction<IOfferBtnClick>
    ) => {
      const offer = state.winbackOffers.find(
        (offer) => offer.id === action.payload.id
      )
      if (!offer) return

      const btn = action.payload.btn

      const isUnclick = unClickOfferBtn(offer, btn)
      if (!isUnclick) return

      // Handle btn is accepted and another offer is marked as accepted
      if (btn === 'accepted') {
        handleAcceptedWinbackOffer(action.payload.id, state.winbackOffers)
      }

      clickOfferBtn(offer, btn)
    },
    updateExpandedActionIndex: (state, action) => {
      state.expandedActionIndex = action.payload
    },
    resetAgentCallSession: (state) => {
      state.account_id = null

      state.call_session_root_cause = null
      state.call_session_subroot_cause = null
      state.call_session_intent = null
      state.reroute = YesNoDropdownItems[1]

      state.already_switched_to_competitor = YesNoDropdownItems[1]
      state.offer_two_months_free = offeredOptionsDefault
      state.selected_callsession_competitor = null
      state.callsession_battlecard_competitor = null
      state.other_competitor_input = null

      state.selected_does_frontier_offer_service = null
      state.new_customer_address_input = null
      state.selected_move_type = null
      state.selected_short_term_move_offer_accepted = offeredOptionsDefault
      state.selected_long_term_move_offer_accepted = offeredOptionsDefault
      state.selected_customer_move_new_tech = null

      state.selectedWinbackSpeed = null
      state.winbackOffers = []

      state.customer = null
      state.dropped_product = null
      state.user = null
      state.outcome = null
      state.user = null
      state.outcome = null
      state.callsession_actions = []
      state.callsession_offers = []

      state.starting_mrc = 0
      state.ending_mrc = 0

      state.start_at = ''

      state.comments = null
      state.no_account_items = false
      state.action_comments = null

      state.dropOfferDialogOpen = false
      state.sessionCanBePerformed = true
      state.expandedActionIndex = null
    },
    updateSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload
    },
    updateAccountId: (state, action) => {
      state.account_id = action.payload
    }
  }
})

export const {
  updateAgentCallSessionCustomer,
  updateAgentCallSessionUser,
  updateAgentCallSessionOutcome,
  updateAgentSelectedCallSessionCompetitor,
  updateAgentCallSessionStartAt,
  updateAgentCallSessionComments,
  updateAgentCallSessionActionComments,
  resetAgentCallSession,
  updateAlreadySwitchedToCompetitor,
  updateDroppedProductId,
  updateReroute,
  handleActionOffered,
  updateCallsessionActionAccept,
  updateCallsessionActionReject,
  handleOfferNotOffered,
  handleOfferAccepted,
  handleOfferRejected,
  handleDropProductClick,
  handleSelectedOfferFromDropProduct,
  toggleOptOffer,
  toggleOffer,
  toggleDefaultOffer,
  setOptOffers,
  setDefaultOffers,
  handleOptOfferClicked,
  handleDefaultOfferClick,
  updateCallSessionIntent,
  updateTransferPin,
  updateCallSessionRootCause,
  updateCallSessionSubRootCause,
  updateSelectedDoesFrontierOfferService,
  updateNewCustomerAddress,
  updateCustomerMoveNewTech,
  updateSelectedMoveType,
  updateShortTermMoveOfferAccepted,
  updateOtherCompetitorInput,
  updateAgentCallSessionActions,
  updateAgentCallSessionOffers,
  updateCallsessionStartingMRC,
  updateAgentCallSessionNoAccountItems,
  updateWinbackOffers,
  closeDropOfferDialog,
  updateSessionCanBePerformed,
  updateWinbackSpeed,
  toggleWinbackOffer,
  handleWinbackOfferClicked,
  updateExpandedActionIndex,
  updateDataUsage,
  updateDevice,
  updatePlan,
  updateLines,
  updateSelectedLanguage,
  updateAccountId,
  updatePinRequested
} = agentCallSessionSlice.actions