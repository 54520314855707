import { AgentCallSession } from '../../callsession/callsessionSlice'
import {
  MoveTypeOptions,
  ServiceCallAbout,
  YesNoOptions
} from '../../../utils/enums'
import { isDisconnectedCustomer } from '../../../lib/utils'

const isCustomerMoveValid = (
  agentCallSessionState: AgentCallSession
): { isValid: boolean; invalidField?: string } => {
  if (agentCallSessionState.call_session_root_cause?.name)
    return { isValid: true }

  // Selected move type is mandatory
  if (!agentCallSessionState.selected_move_type)
    return { isValid: false, invalidField: 'move type' }

  // For short-term move, no other fields are required
  if (
    agentCallSessionState.selected_move_type.name ===
    MoveTypeOptions.shortTermMove
  )
    return { isValid: true }

  // Handle long-term move
  // Mandatory field
  if (!agentCallSessionState.selected_does_frontier_offer_service)
    return { isValid: false, invalidField: 'long term move' }

  // If frontier offers service at new addres, selecting new tech is mandatory
  if (
    agentCallSessionState.selected_does_frontier_offer_service.name ===
      YesNoOptions.yes &&
    !agentCallSessionState.selected_customer_move_new_tech
  )
    return { isValid: false, invalidField: 'new tech' }

  return { isValid: true }
}

const isCompedValid = (
  agentCallSessionState: AgentCallSession
): { isValid: boolean; invalidField?: string } => {
  // For Account Service calls, selecting at least one sub root cause is mandatory
  if (
    agentCallSessionState.call_session_intent?.name ===
      ServiceCallAbout.NotDeactivation &&
    (!agentCallSessionState.call_session_subroot_cause ||
      agentCallSessionState.call_session_subroot_cause?.length < 1)
  ) {
    return { isValid: false, invalidField: 'subRoot cause' }
  }

  // For deactivation calls, selecting the competitor field is mandatory
  if (
    agentCallSessionState.call_session_intent?.name ===
      ServiceCallAbout.Deactivation &&
    !agentCallSessionState.selected_callsession_competitor
  ) {
    return { isValid: false, invalidField: 'competitor' }
  }

  return { isValid: true }
}

const isDevicePlanDataDiscoveryFilled = (
  agentCallSessionState: AgentCallSession
): boolean => {
  return !!(
    agentCallSessionState.device &&
    agentCallSessionState.plan &&
    agentCallSessionState.data_usage
  )
}

export const isContextSectionValid = (
  agentCallSessionState: AgentCallSession
): { isValid: boolean; invalidField?: string } => {
  if (agentCallSessionState.reroute?.name === YesNoOptions.yes) {
    return { isValid: true }
  }

  const validationRules = [
    {
      isValid: !!agentCallSessionState.call_session_root_cause,
      field: 'root cause'
    },
    {
      isValid: isCompedValid(agentCallSessionState).isValid,
      field: isCompedValid(agentCallSessionState).invalidField
    },
    {
      isValid: isCustomerMoveValid(agentCallSessionState),
      field: isCustomerMoveValid(agentCallSessionState).invalidField
    },
    {
      isValid: isDevicePlanDataDiscoveryFilled(agentCallSessionState),
      field: 'offer eligibility criteria'
    },
    {
      isValid: isDisconnectedCustomer(
        agentCallSessionState?.customer?.account_status
      )
    }
  ]

  for (const rule of validationRules) {
    if (!rule.isValid) {
      return { isValid: false, invalidField: rule.field }
    }
  }

  return { isValid: true }
}
