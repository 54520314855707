import { Box, Snackbar, Typography } from '@mui/material'
import { LicenseInfo } from '@mui/x-data-grid-pro'

import SearchBarComponent from 'components/CustomerSearch/SearchBarComponent'
import React, { useEffect } from 'react'
import landingBg from '../../assets/images/landing-bg.svg'
import VerizonNameLogo from '../../assets/verizon-name-logo.svg'
import FrontierBuddyLogo from '../../assets/images/frontier-buddy-icon.svg'
import RecentCustomerInteraction from './RecentCustomerInteraction'
import { useLocation } from 'react-router-dom'
import { userLandingPage } from '../../routes/routes'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { resetCallFlowState } from 'store/callflow/callFlow'
import { useAppDispatch } from 'store/ConfigureStore'
import { fetchCallSessionRootCauses } from 'buddy-api/api/callsession/CallSessionRootCauseAPI'

const InboundOpportunities = (): React.ReactElement => {
  const location = useLocation()
  LicenseInfo.setLicenseKey(
    '308a230302b60e68cdd2b4c8a89dbb7dTz03ODQ4NyxFPTE3MzEyNTY0MTQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
  )
  const [snackbarOpen, setSnackbarOpen] = React.useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation('inboundOpportunities')

  const dispatch = useAppDispatch()

  useEffect(() => {
    const getQueryParam = (param: any) => {
      const queryString = new URLSearchParams(location.search)
      return queryString.get(param)
    }

    const saveSuccessful = getQueryParam('saved')

    if (saveSuccessful === 'true') {
      setSnackbarOpen(true)
    }
  }, [location])

  useEffect(() => {
    dispatch(resetCallFlowState())
    dispatch(fetchCallSessionRootCauses())
  }, [dispatch])

  return (
    <Box display='flex' flexDirection='column'>
      <Box
        sx={{
          position: 'relative',
          backgroundImage: `url(${landingBg})`,
          backgroundColor: 'white',
          height: '40vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            minHeight: '10rem',
            justifyContent: 'center',
            mx: 'auto',
            alignItems: 'center',
            gap: 3
          }}
        >
          <Box
            component='img'
            src={VerizonNameLogo}
            sx={{ maxHeight: '60px' }}
          />
          <Box
            component='img'
            src={FrontierBuddyLogo}
            sx={{ minHeight: '75px' }}
          />
        </Box>
        <SearchBarComponent />
      </Box>
      {
        <Box
          sx={{
            height: '301.5px',
            ml: 5,
            mr: 5,
            mt: 5
          }}
        >
          <Typography
            variant='h2'
            sx={{
              fontWeight: 620,
              fontSize: '14px',
              mb: 1,
              color: '#545454'
            }}
          >
            {t('recentInteractions')}
          </Typography>
          <RecentCustomerInteraction />
        </Box>
      }
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        autoHideDuration={3000}
        message={t('sessionSaved')}
        open={snackbarOpen}
        onClose={() => {
          setSnackbarOpen(false)
          navigate(userLandingPage())
        }}
      />
    </Box>
  )
}

export default InboundOpportunities
