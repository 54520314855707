import React from 'react'
import { defaultFont } from '../../components/ScriptBlock'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const InstructionsRow = () => {
  const { t } = useTranslation('offersInstructionsRow')

  return (
    <Box
      sx={{
        ...defaultFont,
        textTransform: 'none',
        fontSize: '13px',
        fontFamily: 'Open Sans'
      }}
    >
      <Box sx={{ width: '77%' }}>
        <Box sx={{ fontWeight: 'bold' }}>{t('offersGeneralInstruction')}</Box>
      </Box>
    </Box>
  )
}
